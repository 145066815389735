import { useTranslation } from "@hireroo/i18n";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import ScrollableListBox from "../ListBox/ScrollableListBox";
import { ScrollableListBoxProvider } from "../ListBox/ScrollableListBoxContext";

type ValueId = string;

type FieldValue = {
  value: string;
};

type ItemProps = {
  valueId: ValueId;
};
type Source = { optionText: string; chipText?: string; tooltip?: string };

const StyledPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-groupLabel": {
    backgroundColor: theme.palette["Background/Paper"].p4,
  },
}));

const OutlinedChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.action.disabled,
  },
  color: theme.palette.text.primary,
  borderColor: theme.palette.action.disabled,
}));

const OutlinedTextField = styled(TextField)(() => ({
  "& .MuiInputBase-inputAdornedStart": {
    minWidth: "300px !important",
  },
  border: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

export type TagSearchSelectorProps = {
  stateValues: FieldValue[];
  setStateValues: React.Dispatch<React.SetStateAction<FieldValue[]>>;
  suggestions: Omit<ItemProps, "id">[];
  displaySources: Record<ValueId, Source | undefined>;
  loading?: boolean;
  onFocus?: () => void;
  onReachedEnd: () => void;
  onInputChange: (text: string) => void;
  placeholder?: string;
  noOptionsText?: string;
  listMessage?: string;
};

const TagSearchSelector: React.FC<TagSearchSelectorProps> = props => {
  const { t } = useTranslation();
  const { suggestions, displaySources, stateValues, setStateValues } = props;
  const [inputValue, setInputValue] = React.useState<string>("");

  const value = React.useMemo((): ItemProps[] => {
    return stateValues.map(tag => ({ valueId: tag.value }));
  }, [stateValues]);

  return (
    <ScrollableListBoxProvider onReachedEnd={props.onReachedEnd} textMessage={props.listMessage}>
      <Autocomplete
        multiple
        fullWidth
        value={value}
        isOptionEqualToValue={(option, value) => {
          return option.valueId === value.valueId;
        }}
        componentsProps={{
          paper: {
            sx: {
              maxHeight: 600,
              overflowY: "auto",
            },
          },
        }}
        disableCloseOnSelect
        disablePortal
        PopperComponent={StyledPopper}
        ListboxComponent={ScrollableListBox}
        ListboxProps={{
          // giving role to list-box to keep the scroll position when the list is updated
          role: "list-box",
        }}
        renderOption={(props, option) => {
          const displaySource = displaySources[option.valueId];
          return (
            <Tooltip key={option.valueId} title={displaySource?.tooltip ?? ""}>
              <ListItem {...props} key={option.valueId}>
                {displaySource?.optionText ?? ""}
              </ListItem>
            </Tooltip>
          );
        }}
        onChange={(event, items, reason) => {
          if (reason === "selectOption") {
            setStateValues(items.map(item => ({ value: item.valueId })));
          } else if (reason === "removeOption") {
            const itemsSet = new Set(items.map(item => item.valueId));
            setStateValues(prevTags => prevTags.filter(tag => itemsSet.has(tag.value)));
          } else if (reason === "clear") {
            setStateValues([]);
          }
        }}
        onFocus={props.onFocus}
        loading={props.loading}
        loadingText={t("読み込み中...")}
        noOptionsText={props.suggestions.length === 0 ? t("検索条件に該当するタグがありません。") : t("読み込み中...")}
        options={suggestions as ItemProps[]}
        filterOptions={options => options}
        getOptionLabel={option => {
          const displayName = displaySources[option.valueId];
          if (!displayName) {
            return "";
          }
          return displayName.optionText;
        }}
        renderTags={(items, getTagProps) => {
          return items.map((item, index) => {
            const tagProps = getTagProps({ index });
            const displaySource = displaySources[item.valueId];
            return (
              <Tooltip key={item.valueId} title={displaySource?.tooltip ?? ""}>
                <OutlinedChip
                  {...tagProps}
                  key={item.valueId}
                  label={displaySource?.chipText ?? displaySource?.optionText ?? item.valueId}
                  variant="outlined"
                  onDelete={event => {
                    tagProps.onDelete(event);
                    setStateValues(prevTags => prevTags.filter(tag => item.valueId !== tag.value));
                  }}
                />
              </Tooltip>
            );
          });
        }}
        inputValue={inputValue}
        onInputChange={(event, value, reason) => {
          if (reason === "input") {
            props.onInputChange(value);
            setInputValue(value);
          } else if (reason === "clear") {
            props.onInputChange("");
            setInputValue("");
          }
        }}
        renderInput={params => {
          const textFieldProps: TextFieldProps = {
            ...params,
            inputProps: {
              ...params.inputProps,
              onKeyDown: event => {
                if (event.key === "Backspace") {
                  // Prevent delete chip when input is empty
                  event.stopPropagation();
                }
              },
            },
            InputProps: {
              ...params.InputProps,
              autoFocus: true,
            },
            color: "secondary",
            placeholder: props.placeholder,
          };
          return <OutlinedTextField {...textFieldProps} />;
        }}
      />
    </ScrollableListBoxProvider>
  );
};

TagSearchSelector.displayName = "MultiAssignField";

export default TagSearchSelector;
