import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useScrollableListBoxContext } from "./ScrollableListBoxContext";

const StyledUL = styled("ul")(
  ({ theme }) => `
& .MuiAutocomplete-option[aria-selected='true'] {
  & .Mui-focused {
    background-color: ${theme.palette["Gray/Shades"].p16};
  }
  background-color: ${theme.palette["Secondary/Shades"].p16} !important;
}
& .MuiAutocomplete-option[aria-selected='false'] {
  &:hover {
    background-color: ${theme.palette["Gray/Shades"].p8};
  }
}
`,
);

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  backgroundColor: theme.palette["Background/Paper"].p16,
  width: "100%",
  padding: `2px 16px`,
}));

export type RefType = React.RefObject<HTMLDivElement> | ((instance: HTMLDivElement | null) => void) | null | undefined;

export type ScrollableListBoxProps = React.HtmlHTMLAttributes<HTMLUListElement>;

/**
 * This component is for autocomplete ListBox.
 * In order to pass this component to Autocomplete, this props should be React.HtmlHTMLAttributes<HTMLUListElement>
 */
const ScrollableListBox = React.forwardRef((props: ScrollableListBoxProps, ref: RefType) => {
  const { children, ...rest } = props;
  const scrollRootRef = React.useRef<HTMLUListElement>(null);
  const targetRef = React.useRef<HTMLDivElement>(null);

  /**
   * props cannot be extended in this component. So, we use context to pass the onReachedEnd.
   */
  const { onReachedEnd, textMessage } = useScrollableListBoxContext();

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => entry.isIntersecting && onReachedEnd());
      },
      {
        root: scrollRootRef.current,
        rootMargin: "0px",
        threshold: 0.25,
      },
    );
    const targetElement = targetRef.current;

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [onReachedEnd]);

  return (
    <div ref={ref}>
      {textMessage && <StyledTypography fontSize={12}>{textMessage}</StyledTypography>}
      <StyledUL ref={scrollRootRef} {...rest}>
        {children}
        <div ref={targetRef} />
      </StyledUL>
    </div>
  );
});

ScrollableListBox.displayName = "ListBox";

export default ScrollableListBox;
