import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Icon, { IconProps } from "../../../../subblocks/Icon/Icon";

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "46px",
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
}));

export type TestCaseProps = {
  icon: IconProps;
  title: string;
  forceExpanded: boolean;
  defaultExpanded?: boolean;
};

const TestCase: React.FC<React.PropsWithChildren<TestCaseProps>> = props => {
  const [expanded, setExpanded] = React.useState<boolean>(props.defaultExpanded ?? false);

  React.useEffect(() => {
    setExpanded(props.forceExpanded);
  }, [props.forceExpanded]);

  const handleClick: AccordionSummaryProps["onClick"] = () => {
    setExpanded(draft => !draft);
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} onClick={handleClick}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {props.icon && <Icon {...props.icon} />}
          <Typography>{props.title}</Typography>
        </Stack>
      </StyledAccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};

TestCase.displayName = "TestCase";

export default TestCase;
