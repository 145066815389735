import { useFetchIpAddress } from "@hireroo/app-helper/hooks";
import { generateEntityKey } from "@hireroo/app-helper/normalizer";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth } from "@hireroo/app-store/essential/talent";
import { AssessmentsIdStore } from "@hireroo/app-store/page/t/assessments_id";
import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { SystemDesignCodingEditor } from "@hireroo/app-store/widget/shared/SystemDesignCodingEditor";
import { ExamNavigation } from "@hireroo/app-store/widget/t/ExamNavigation";
import * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import ChallengeCodingEditorInitialContainer, {
  ChallengeCodingEditorInitialContainerProps,
} from "../../../../widget/v2/shared/ChallengeCodingEditor/InitializeContainer";
import ProjectCodingEditorContainerV3, {
  ProjectCodingEditorInitialContainerV3Props,
} from "../../../../widget/v2/shared/ProjectCodingEditorV3/InitialContainer";
import ProjectCodingEditorContainerV4, {
  ProjectCodingEditorV4FetchContainerProps,
} from "../../../../widget/v2/shared/ProjectCodingEditorV4/FetchContainer";
import QuizCodingEditorInitialContainer, {
  QuizCodingEditorInitialContainerProps,
} from "../../../../widget/v2/shared/QuizCodingEditor/InitialContainer";
import ScreeningTestTutorialContainer from "../../../../widget/v2/shared/ScreeningTestTutorial/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import SystemDesignCodingEditorInitialContainer, {
  SystemDesignCodingEditorInitialContainerProps,
} from "../../../../widget/v2/shared/SystemDesignCodingEditor/InitialContainer";
import TestAnnouncementContainer from "../../../../widget/v2/shared/TestAnnouncement/Container";
import FooterContainer from "../../../../widget/v2/t/Footer/Container";
import ScreeningTestHeaderContainer, { ScreeningTestHeaderContainerProps } from "../../../../widget/v2/t/ScreeningTestHeader/Container";

export type GenerateAssessmentPropsArgs = {
  examId: string;
};

type EntityComponentMap = Record<string, React.ReactNode>;

export const useGenerateProps = (args: GenerateAssessmentPropsArgs): Pages.AssessmentProps => {
  const { t } = useTranslation();
  const uid = Auth.useCurrentUid();
  const user = Auth.useCurrentUser();
  const messageForTalent = AssessmentsIdStore.useExamMessageForTalent();
  const examStatus = AssessmentsIdStore.useExamStatus();
  /**
   * TODO Check
   */
  const talentName = "";
  const entities = AssessmentsIdStore.useEntities();
  const endExamStatus = AssessmentsIdStore.useEndExamStatus();
  const endExamLoadingStatus = AssessmentsIdStore.useEndExamLoadingStatus();
  const currentEntityKey = ExamNavigation.useCurrentSelectedEntityKey();
  const loadingStatus = ExamNavigation.useLoadingStatus();
  const client = getGraphqlClient();
  const assessmentName = AssessmentsIdStore.useAssessmentName();
  const allQuestionsHaveBeenSubmitted = ExamNavigation.useAllQuestionsHaveBeenSubmitted();
  const ipInfo = useFetchIpAddress();

  const metaTitle = React.useMemo((): string => {
    switch (examStatus) {
      case "CREATED":
      case "STARTED":
      case "COMPLETED":
      case "FINALIZED":
        return assessmentName;
      default:
        return t("テスト終了");
    }
  }, [examStatus, assessmentName, t]);

  useTitle(metaTitle);

  const interviewNavigationContainerProps: Omit<ScreeningTestHeaderContainerProps, "showingMode"> = {
    onEndExam: () => {
      AssessmentsIdStore.updateEndExamLoadingStatus("PENDING");
      client
        .EndExam({
          input: {
            examId: args.examId,
          },
        })
        .then(res => {
          AssessmentsIdStore.setExam(res.endExam);
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("テストの終了に失敗しました。お手数ですがヘルプセンターより運営にお問い合わせください。"),
          });
        })
        .finally(() => {
          AssessmentsIdStore.updateEndExamLoadingStatus("READY");
        });
    },
    willBlockEndInterview: !allQuestionsHaveBeenSubmitted,
    disabledFinishButton: endExamLoadingStatus === "PENDING",
    messageForCandidate: messageForTalent,
  };

  const ContentMap: EntityComponentMap = React.useMemo<EntityComponentMap>(() => {
    return entities.reduce<EntityComponentMap>((all, entity) => {
      const key = generateEntityKey(entity);
      switch (entity.__typename) {
        case "ChallengeEntity": {
          if (ipInfo?.ipAddress && ipInfo?.geolocation) {
            ChallengeCodingEditor.setIpAddress(ipInfo.ipAddress);
            ChallengeCodingEditor.setGeolocation(ipInfo.geolocation);
          }
          const challengeCodingEditor: ChallengeCodingEditorInitialContainerProps = {
            uid: uid ?? "",
            entityId: entity.challengeEntityId,
            editorKind: "CANDIDATE",
            interviewKind: "INTERVIEW",
            displayName: talentName,
            enableBrowserEventDetector: true,
            // Currently, talent cannot use Web search and ChatGPT
            enabledWebSearch: false,
            enabledChatGPT: false,
          };
          return {
            ...all,
            [key]: <ChallengeCodingEditorInitialContainer key={key} {...challengeCodingEditor} />,
          };
        }
        case "ProjectEntity": {
          switch (entity.question?.projectVersion) {
            case Graphql.ProjectVersion.V2:
            case Graphql.ProjectVersion.V3: {
              if (ipInfo?.ipAddress && ipInfo?.geolocation) {
                ProjectCodingEditorV3.setIpAddress(ipInfo.ipAddress);
                ProjectCodingEditorV3.setGeolocation(ipInfo.geolocation);
              }
              const projectCodingEditorInitialContainerProps: ProjectCodingEditorInitialContainerV3Props = {
                mode: "DEVELOPMENT",
                uid: uid ?? "",
                userName: user.displayName,
                entityId: entity.projectEntityId,
                endpointId: entity.projectEntityId,
                questionId: entity.question?.questionId ?? 0,
                questionVersion: entity.question?.version ?? "v2.0.0",
                submissionId: 0,
                isCandidate: true,
                enableBrowserEventDetector: true,
              };
              return { ...all, [key]: <ProjectCodingEditorContainerV3 key={key} {...projectCodingEditorInitialContainerProps} /> };
            }
            case Graphql.ProjectVersion.V4: {
              const projectCodingEditorV4FetchContainerProps: ProjectCodingEditorV4FetchContainerProps = {};
              return { ...all, [key]: <ProjectCodingEditorContainerV4 key={key} {...projectCodingEditorV4FetchContainerProps} /> };
            }
            case undefined: {
              throw new Error(`Not found entity question`);
            }
            default:
              throw new Error(`Unsupported Project Version: ${entity.question?.projectVersion}`);
          }
        }
        case "QuizEntity": {
          if (ipInfo?.ipAddress && ipInfo?.geolocation) {
            QuizCodingEditor.setIpAddress(ipInfo.ipAddress);
            QuizCodingEditor.setGeolocation(ipInfo.geolocation);
          }
          const quizCodingEditorInitialContainerProps: QuizCodingEditorInitialContainerProps = {
            uid: uid ?? "",
            entityId: entity.quizEntityId,
            packageId: entity.pb_package?.packageId ?? 0,
            firstQuestionId: entity.pb_package?.questions.at(0)?.id ?? 0,
            interviewKind: "INTERVIEW",
            enableBrowserEventDetector: true,
          };
          return {
            ...all,
            [key]: <QuizCodingEditorInitialContainer {...quizCodingEditorInitialContainerProps} />,
          };
        }
        case "SystemDesignEntity": {
          if (ipInfo?.ipAddress && ipInfo?.geolocation) {
            SystemDesignCodingEditor.setIpAddress(ipInfo.ipAddress);
            SystemDesignCodingEditor.setGeolocation(ipInfo.geolocation);
          }
          const systemDesignCodingEditorInitialContainerProps: SystemDesignCodingEditorInitialContainerProps = {
            operator: {
              kind: "CANDIDATE",
              displayName: talentName,
            },
            interviewKind: "INTERVIEW",
            showingOptions: [],
            uid: uid ?? "",
            entityId: entity.systemDesignEntityId,
            enableBrowserEventDetector: true,
          };
          return {
            ...all,
            [key]: <SystemDesignCodingEditorInitialContainer key={key} {...systemDesignCodingEditorInitialContainerProps} />,
          };
        }
        default: {
          return all;
        }
      }
    }, {});
  }, [talentName, entities, ipInfo, uid, user.displayName]);

  if (endExamStatus) {
    return {
      layout: {
        loading: loadingStatus === "LOADING",
        Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="READONLY" />,
        Footer: <FooterContainer />,
        Snackbar: <SnackbarContainer />,
        Tutorial: null,
      },
      Content: <TestAnnouncementContainer kind={endExamStatus} />,
    };
  }

  switch (examStatus) {
    case "STARTED": {
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="RUNNING" />,
          Footer: <FooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: <ScreeningTestTutorialContainer />,
        },
        Content: currentEntityKey ? ContentMap[currentEntityKey] : undefined,
      };
    }
    case "COMPLETED":
    case "FINALIZED": {
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="READONLY" />,
          Footer: <FooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: null,
        },
        Content: <TestAnnouncementContainer kind="FINISH_TEST" />,
      };
    }
    default:
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="READONLY" />,
          Footer: <FooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: <ScreeningTestTutorialContainer />,
        },
        Content: <TestAnnouncementContainer kind="TEST_HAS_ALREADY_BEEN_COMPLETED" />,
      };
  }
};
