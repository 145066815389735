import { useStringDebouncedCallback } from "@hireroo/app-helper/react-use";
import * as SkillTagHelper from "@hireroo/app-helper/skill-tag";
import { Company } from "@hireroo/app-store/essential/employee";
import { QuestionSearchArea } from "@hireroo/app-store/widget/e/QuestionSearchArea";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type DisplaySources = Widget.RemoteInterviewQuestionSearchAreaProps["skillTagsField"]["selector"]["displaySources"];

export const useGenerateSkillTagsFieldProps = (): Widget.RemoteInterviewQuestionSearchAreaProps["skillTagsField"] => {
  const { t } = useTranslation();
  const skillTagRes = QuestionSearchArea.useSkillTagRes();
  const currentSkillTags = QuestionSearchArea.useCurrentSkillTags();
  const skillTagSources = QuestionSearchArea.useSkillTagSources();
  const textFilter = QuestionSearchArea.useSkillTagTextFilter();
  const nextOffset = QuestionSearchArea.useSkillTagNextOffset();
  const debounced = useStringDebouncedCallback({
    callback: React.useCallback(
      value => {
        if (value !== textFilter) {
          QuestionSearchArea.clearCurrentSkillTagState();
        }
        QuestionSearchArea.updateSkillTagTextFilter(value);
      },
      [textFilter],
    ),
    delayInMicroSeconds: 500,
  });
  const companyId = Company.useStrictActiveCompanyId();
  const [resultAssigned] = Graphql.useGetAssignedSkillTagIdsForQuestionsQuery({
    variables: {
      companyId: companyId,
    },
    requestPolicy: "cache-and-network",
  });
  const assignedSkillTagIdsSet = React.useMemo(() => {
    return new Set(resultAssigned.data?.assignedSkillTagIds.skillTagIds || []);
  }, [resultAssigned.data]);

  const [result, refresh] = Graphql.useGetHierarchicalSkillTagsForQuestionsQuery({
    variables: {
      input: {
        name: textFilter,
        depth: 1,
        offset: nextOffset,
        limit: QuestionSearchArea.SKILL_TAG_SIZE,
        withCount: false,
      },
    },
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data) {
      QuestionSearchArea.updateSkillTags(result.data.hierarchicalSkillTags);
    }
  }, [result.data]);

  return {
    selector: {
      displaySources: React.useMemo(() => {
        const skillTagUniqueNameMap = SkillTagHelper.generateUniqueNameMapFromPath(
          skillTagSources
            .filter(skillTag => assignedSkillTagIdsSet.has(skillTag.skillTagNodeId))
            .map(skillTag => ({
              id: skillTag.skillTagNodeId,
              name: skillTag.name,
              path: skillTag.path,
            })),
        );
        return skillTagSources.reduce<DisplaySources>((all, skillTag) => {
          all[skillTag.skillTagNodeId] = {
            optionText: skillTagUniqueNameMap.get(skillTag.skillTagNodeId) || skillTag.name,
            tooltip: skillTag.path,
          };
          return all;
        }, {});
      }, [assignedSkillTagIdsSet, skillTagSources]),
      suggestions: currentSkillTags
        .filter(skillTag => assignedSkillTagIdsSet.has(skillTag.skillTagNodeId))
        .map(skillTag => ({ valueId: skillTag.skillTagNodeId })),
      listMessage: textFilter.length > 0 ? t("関連するタグも表示されます。") : undefined,
      loading: result.fetching || resultAssigned.fetching,
      placeholder: t("スキルタグを検索"),
      onReachedEnd: () => {
        if (skillTagRes?.hasNext && !result.fetching) {
          QuestionSearchArea.updateSkillTagNextOffset();
        }
      },
      onInputChange: text => {
        debounced(text);
      },
    },
    onChange: fields => {
      // Clear skillTagState fields if the field is empty
      if (fields.length === 0) {
        QuestionSearchArea.clearCurrentSkillTagState();
      }
    },
    splitButton: {
      onOpen: () => {
        if (!result.fetching) {
          refresh();
        }
      },
      onClose: () => {
        QuestionSearchArea.clearCurrentSkillTagState();
      },
    },
  };
};
