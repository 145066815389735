import Box from "@mui/material/Box";
import * as React from "react";

import ResultBlocks, { ResultBlocksProps } from "../ResultBlocks/ResultBlocks";
import { testResultData } from "./ResultBlocks.StoryExample";

export type ResultBlocksPlaygroundProps = {};

/**
 * Only for dev
 */
const ResultBlocksPlayground: React.FC<ResultBlocksPlaygroundProps> = _props => {
  const resultBlocks: ResultBlocksProps = testResultData;

  return (
    <Box px={20}>
      <ResultBlocks {...resultBlocks} />
    </Box>
  );
};

ResultBlocksPlayground.displayName = "ResultBlocksPlayground";

export default ResultBlocksPlayground;
