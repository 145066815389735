import * as React from "react";

type Callback = () => void;

const NOOP: Callback = () => undefined;

export type ContextValue = {
  onReachedEnd: () => void;
  textMessage?: string;
};

export const ScrollableListBoxContext = React.createContext<ContextValue>({
  onReachedEnd: NOOP,
});

export const useScrollableListBoxContext = () => React.useContext(ScrollableListBoxContext);

export type ScrollableListBoxProviderProps = {
  onReachedEnd: () => void;
  textMessage?: string;
};

export const ScrollableListBoxProvider: React.FC<React.PropsWithChildren<ScrollableListBoxProviderProps>> = props => {
  const { onReachedEnd } = props;
  const value: ContextValue = {
    onReachedEnd: React.useCallback(() => {
      onReachedEnd();
    }, [onReachedEnd]),
    textMessage: props.textMessage,
  };

  return <ScrollableListBoxContext.Provider value={value}>{props.children}</ScrollableListBoxContext.Provider>;
};
