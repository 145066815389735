import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ProjectCodingEditorV4ContainerProps = {};

const ProjectCodingEditorV4Container: React.FC<ProjectCodingEditorV4ContainerProps> = () => {
  const projectCodingEditorV4Props = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ProjectCodingEditorV4 {...projectCodingEditorV4Props} />
    </ErrorBoundary>
  );
};

ProjectCodingEditorV4Container.displayName = "ProjectCodingEditorV4Container";

export default withErrorBoundary(ProjectCodingEditorV4Container, {});
