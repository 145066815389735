import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ProjectCodingEditorV4Container, { ProjectCodingEditorV4ContainerProps } from "./Container";

export type ProjectCodingEditorV4FetchContainerProps = {};

const ProjectCodingEditorV4FetchContainer: React.FC<ProjectCodingEditorV4FetchContainerProps> = () => {
  const containerProps: ProjectCodingEditorV4ContainerProps = {};

  return <ProjectCodingEditorV4Container {...containerProps} />;
};

ProjectCodingEditorV4FetchContainer.displayName = "ProjectCodingEditorV4FetchContainer";

export default withErrorBoundary(ProjectCodingEditorV4FetchContainer, {});
