import Box from "@mui/material/Box";
import * as React from "react";

export type ProjectCodingEditorV4Props = {};

const ProjectCodingEditorV4: React.FC<ProjectCodingEditorV4Props> = _props => {
  return <Box>TODO</Box>;
};

ProjectCodingEditorV4.displayName = "ProjectCodingEditorV4";

export default ProjectCodingEditorV4;
