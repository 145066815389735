import type * as Interface from "@hireroo/evaluation-result-interface";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Dialog, DialogProps, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import OriginalCarousel from "react-material-ui-carousel";
import type { CarouselProps as OriginalCarouselProps } from "react-material-ui-carousel/dist/components/types";

import Icon from "../../subblocks/Icon/Icon";

const StyledStack = styled(Stack)(() => ({
  cursor: "pointer",
  position: "relative",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  top: "calc(50% - 20px) !important",
  zIndex: 1,
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette["Common/Black"].p30,
}));

const StyledDialog = styled(Dialog)(() => ({
  backgroundColor: "transparent",
}));

const StyledImage = styled("img")`
  border-radius: 4px;
  width: 100%;
`;

export type CarouselProps = Interface.Carousel["value"];

const Carousel: React.FC<CarouselProps> = props => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const theme = useTheme();
  const lang = useLanguageCode();

  const dialogProps: DialogProps = {
    open: open,
    onClose: () => {
      setOpen(false);
    },
    PaperProps: {
      style: {
        backgroundColor: "transparent",
        boxShadow: "none",
        backgroundImage: "none",
      },
      sx: {
        px: 3,
      },
    },
  };
  const carouselProps: OriginalCarouselProps = {
    sx: {
      // For next and prev buttons
      px: 4,
    },
    index: activeIndex,
    autoPlay: false,
    animation: "fade",
    duration: 1,
    navButtonsAlwaysVisible: true,
    cycleNavigation: false,
    fullHeightHover: false,
    height: "400px",
    indicators: true,
    NavButton: ({ prev, onClick }) => {
      const handleClick = () => {
        onClick();
      };
      return (
        <StyledIconButton onClick={handleClick}>
          {prev ? (
            <KeyboardArrowLeftIcon htmlColor={theme.palette.text.secondary} />
          ) : (
            <KeyboardArrowRightIcon htmlColor={theme.palette.text.secondary} />
          )}
        </StyledIconButton>
      );
    },
    navButtonsWrapperProps: {
      className: "custom-button",
      style: {},
    },
    indicatorContainerProps: {
      style: {
        width: "400px",
        height: 20,
        position: "relative",
      },
    },
    indicatorIconButtonProps: {
      style: {
        cursor: "pointer",
        transition: "200ms",
        padding: "4px",
      },
    },
    activeIndicatorIconButtonProps: {
      style: {
        color: theme.palette.secondary.main,
      },
    },
  };

  const ScreenShots = (
    <StyledDialog {...dialogProps}>
      <React.Suspense>
        <OriginalCarousel {...carouselProps}>
          {props.items.map((item, index) => (
            <Box key={`image-${index}`}>
              <img src={item.image.src} alt={item.image.alt || `testCase-scenario-${index}`} width="100%" style={{ borderRadius: "4px" }} />
            </Box>
          ))}
        </OriginalCarousel>
      </React.Suspense>
    </StyledDialog>
  );
  return (
    <Box>
      <Grid container spacing={2}>
        {props.items.map((item, index) => {
          const handleClick = () => {
            setOpen(prev => {
              return !prev;
            });
            setActiveIndex(index);
          };
          return (
            <Grid item key={`grid-item-${index}`}>
              <StyledStack key={`carousel-item-${index}`} onClick={handleClick}>
                <Stack direction="row" p={0.5} spacing={1} alignItems="center">
                  <Icon kind={item.mark} />
                  {item.label && (
                    <Typography fontSize={14} color="textSecondary">
                      {resolveLanguage(item.label, lang, "translateText")}
                    </Typography>
                  )}
                </Stack>
                <StyledImage src={item.image.src} alt={item.image.alt || `testCase-scenario-${index}`} />
              </StyledStack>
            </Grid>
          );
        })}
      </Grid>
      {ScreenShots}
    </Box>
  );
};

Carousel.displayName = "Carousel";

export default Carousel;
